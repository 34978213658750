<template>
  <div class="wrap">
    <Title name="我的竞卖"></Title>

    <div class="container">
      <div class="search-box">
        <el-form inline label-width="86px">
          <el-form-item label="发布时间:">
            <el-date-picker
              v-model="searchForm.time"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="状态:">
            <el-select v-model="searchForm.state" clearable placeholder="请选择">
              <el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getMyAutionInfo(0)">查询</el-button>
          </el-form-item>
        </el-form>
      </div>

      <div class="tabel-content">
        <el-table :data="tableData" :header-cell-style="{background: '#f9faff', 'font-weight': 700, color: '#666', padding: '12 5px','text-align':'center'}" :cell-style="{'text-align':'center'}" style="width: 100%">
          <el-table-column prop="prodName" label="产品名称" width="130"> </el-table-column>
          <el-table-column prop="name" label="所属类型"> </el-table-column>
          <el-table-column prop="prodModels" label="规格型号"> </el-table-column>
          <el-table-column prop="amount" label="数量">
            <template slot-scope="scope"> {{ scope.row.amount }}{{ scope.row.units }} </template>
          </el-table-column>
          <!--<el-table-column-->
          <!--prop="units"-->
          <!--label="单位">-->
          <!--</el-table-column>-->
          <el-table-column prop="upPrice" label="起拍价"> </el-table-column>
          <el-table-column prop="bottomPrice" label="最低价"> </el-table-column>
        <el-table-column prop="releaseName" width="180" label="发布人"> </el-table-column>
          
          <el-table-column prop="checkState" label="状态">
            <template slot-scope="scope">
              {{ scope.row.checkState | getApprvStatus }}
            </template>
          </el-table-column>
          <el-table-column prop="endTime" label="到期时间" width="170"> </el-table-column>
        <el-table-column prop="checkTime" width="180" label="审核时间"> </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <!--new Date(scope.row.startTime)<new Date()-->
              <!-- <el-button type="text" size="small" @click="handleClick(scope.row)">查看</el-button> -->
              <el-button type="text" size="small" v-if="scope.row.checkState == 'P' && new Date(scope.row.startTime) > new Date() && sellExamine" @click="handleWithdraw(scope.row)">撤回</el-button>
              <el-button type="text" size="small" v-if="(scope.row.checkState === 'R'  || scope.row.checkState === 'F'|| scope.row.checkState === 'N') && sellAuPush" @click="handleEditClick(scope.row)">修改</el-button>
              <el-button v-if="  scope.row.checkState === 'A' && sellExamine " size="small" type="text" @click="toExamine(scope.row.id,scope.row)">审核</el-button>
                <div>
              <el-button v-if="(scope.row.checkState === 'P' || scope.row.checkState === 'A'|| scope.row.checkState === 'F' || scope.row.checkState === 'R' )" size="small" type="text" @click="toNewDetail(scope.row.id,scope.row)">查看详情</el-button>
              </div>
              <!-- <el-button v-if="scope.row.checkState === 'P' && new Date(scope.row.startTime) < new Date()" size="small" type="text" @click="toNewDetail(scope.row.id,scope.row)">查看详情</el-button> -->
              <!-- <el-button v-permission="{either:true,value:['sell:examine']}" type="text" size="small" v-if="scope.row.checkState=='A' "  @click="checkBid(scope.row)">审核</el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <el-dialog :visible.sync="checkOpen" title="竞卖审核" @close="approveDialogVisible = false">
          <el-form ref="form">
            <el-form-item label="是否通过：">
              <el-radio-group v-model="form.checkState">
                <el-radio label="P">通过</el-radio>
                <el-radio label="F">拒绝</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="审核备注：" prop="traceMsg">
              <el-input v-model="form.checkNote" />
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="cancel()">取消</el-button>
            <el-button type="primary" @click="submitForm">确认</el-button>
          </div>
        </el-dialog>

        <div class="pagination" v-if="pageManage.total">
          <el-pagination background layout="prev, pager, next" :total="pageManage.total" :page-size="pageManage.limit" :current-page="pageManage.page" @current-change="changeCurrentPage">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '../../component/Title';
import permission from '../../../../directive/permission';
import {myAutionInfolist, myAutionInfoWithdraw, checkbid} from '../../api';

export default {
    directives: {
	      permission
	    },
  components: {
    Title
  },
  data() {
    return {
      sellAuPush:false,
      sellExamine:false,
      form: {
        checkState: 'P',
        checkNote: '',
        id: ''
      },
      approveDialogVisible: false,
      checkOpen: false,
      dialogVisible: false,
      pageManage: {
        limit: 10,
        page: 1,
        total: 0
      },

      stateList: [
        {value: 'A', label: '审核中'},
        {value: 'P', label: '已通过'},
        {value: 'F', label: '已拒绝'}
      ],
      searchForm: {
        time: '',
        state: ''
      },
      tableData: []
    };
  },

  created() {
    this.$store.commit('changeMenuType', 'SELL');
    this.getMyAutionInfo(0);
      if (localStorage.getItem('userPermission').indexOf('sell:au:push') !==-1) {
       this.sellAuPush=true
      }
    if (localStorage.getItem('userPermission').indexOf('sell:examine') !==-1) {
       this.sellExamine=true
      }
  },

  filters: {
    getApprvStatus(val) {
      switch (val) {
        case 'A':
          return '待审核';
          break;
        case 'P':
          return '审核通过';
          break;
        case 'F':
          return '审核未通过';
          break;
        case 'N':
          return '未提交';
          break;
        case 'R':
          return '已撤回';
          break;
      }
    }
  },

  methods: {
    changeCurrentPage(num) {
      this.pageManage.page = num;
      this.getMyAutionInfo(1);
    },

    getMyAutionInfo(type) {
      let _this = this;
      if (this.searchForm.time == null) {
        this.searchForm.time = '';
      }
      //0 代表查询按钮点击进来的，默认显示第一页数据
      //1 代表分页点进来的
      if (type == 0) {
        this.pageManage.page = 1;
      }
      myAutionInfolist({
        time: this.searchForm.time.toString(),
        checkState: this.searchForm.state,
        page: this.pageManage.page,
        limit: this.pageManage.limit
      }).then(res => {
        _this.tableData = res.data.items;
        _this.pageManage.total = res.data.total;
      });
    },

    //撤回
    handleWithdraw(row) {
      this.$confirm('您确定撤回吗？')
        .then(() => {
          //区分是撤回还是修改
          row.prodName = null;
          myAutionInfoWithdraw(row).then(res => {
            this.getMyAutionInfo(1);
            if (res.errno === 0) {
              this.$notify.success({
                title: '成功',
                message: res.data
              });
            }
          });
        })
        .catch(() => {
          //  　　do something ... (取消)
        });
    },

    // 查看
    handleClick(row) {
      this.$router.push({
        path: './MyBiddingInfo',
        query: {id: row.id}
      });
    },
    // 修改
    handleEditClick(row) {
      //如果竞卖已经开始，并且通过了审核，则无法修改
      if (row.checkState == 'P' && new Date(row.startTime) < new Date()) {
        this.$notify.error({
          title: '失败',
          message: '该竞卖已经开始无法进行修改'
        });
        return;
      }
      this.$router.push({
        path: './PublishBidding?id=' + row.id,
         query: {
          isEdit: true,
        }
      });
    },
      //审核
    toExamine (id,row) {
      console.log('row',row);
      if (row.approveRemarks =='' || row.approveRemarks==undefined) {
      this.approveRemarksShow=false
      }else{
       this.approveRemarksShow=true
      }
      this.$router.push({
        path: '/User/PublishBidding',
        query: {
          id,
          isEdit: true,
          isExamine:true,
          isRemarksShow:this.approveRemarksShow
        }
      })
    },
      //查看详情 
    toNewDetail(id,row){

      if (row.approveRemarks =='' || row.approveRemarks==undefined ||  row.apprvStatus=='A') {
      this.approveRemarksShow=false
      }else{
       this.approveRemarksShow=true
      }
        this.$router.push({
        path: '/User/PublishBidding',
        query: {
          id,
          isEdit: true,
          isExamine:true,
          isHistory:true,
          isRemarksShow:this.approveRemarksShow
        }
      })
    },
    //
    // initmyAutionInfolist(){
    //     myAutionInfolist({
    //     checkState: this.searchForm.state,
    //     time: this.searchForm.time.toString()
    //     }).then(
    //             res=>{
    //
    //             }
    //             )
    //
    // }

    submitForm() {
      checkbid(this.form).then(res => {
        if (res.errno === 0) {
          this.$notify.success({
            title: '成功',
            message: res.data
          });
        }
        this.checkOpen = false;
        this.getMyAutionInfo(0);
      });
    },
    cancel() {
      this.checkOpen = false;
    },
    checkBid(row) {
      this.checkOpen = true;
      console.log(row);
      this.form.id = row.id;
    }
  }
};
</script>

<style lang="less" scoped>
.wrap {
  background: #fff;

  .container {
    padding: 10px;
    .tabel-content{
     .pagination{
          padding: 20px;
    box-sizing: border-box;
    text-align: right;
       }
    }

    .search-box {
      margin-top: 12px;
    }
  }
}

</style>
